import React from 'react';
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

function UserNav({classprop, auth,}){
  let username = auth.user;
  return(
    <Typography variant="h6" color="secondary" className={classprop}>
    {username}
    </Typography>
  )
}


const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(UserNav);
