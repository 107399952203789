import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';




function renderInput(inputProps, clearSelection) {
  const { InputProps, classes, ref, ...other } = inputProps;
  return (
    <TextField
      onClick={clearSelection}
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />

  );
}

renderInput.propTypes = {
  classes: PropTypes.object.isRequired,
  InputProps: PropTypes.object,
};


function renderSuggestion(suggestionProps) {
  const { suggestion, index, itemProps, highlightedIndex, selectedItem, addSpecieTot,addLayerTot, clearSelection } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.codpign) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.codpign}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.codpign}{' — '}{suggestion.genere_specie_sottospecie}
    </MenuItem>
  );

}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.number]).isRequired,
  index: PropTypes.number.isRequired,
  itemProps: PropTypes.object.isRequired,
  selectedItem: PropTypes.string.isRequired,
  suggestion: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

function getSuggestions(value,specie, { showEmpty = false } = {}) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0 && !showEmpty
    ? []
    : specie.filter(suggestion => {
        const keep =
          count < 15 && suggestion.genere_specie_sottospecie.toString().slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }
        return keep;
      });

}



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 'auto',
  },
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  inputRoot: {
    flexWrap: 'wrap',
    borderBottom: 'none',
  },
  inputInput: {
    width: 'auto',
    flexGrow: 1,
    backgroundColor:'white',
    opacity:1,
    padding:11,
    borderBottom: 'none',
    borderRadius:0,
  },
}));

let popperNode;

////
 function InputSpecie({addSpecieTot, addLayerTot}) {
   const classes = useStyles();

    const [specie, setSpecie] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)


  useEffect(() => {

      setLoading(true)
      setError(null)
      fetch('https://app.floralpinabergamasca.net/api/elespe/?format=json')
        .then(res => res.json())
        .then(json => {
          setLoading(false)
          if (json) {
            setSpecie(json)
          } else {
            setSpecie([])
          }
        })
        .catch(err => {
          setError(err)
          setLoading(false)
        })
    }, [])


  return (
    <div className={classes.root}>
      <Downshift id="downshift-popper"
       onChange={(selectedItem) =>
        specie.map(spec=>{
          if(spec.codpign == selectedItem){
            addSpecieTot(spec.codpign);
            addLayerTot(spec.codpign);
          };
        })
      }>

        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
          clearSelection,
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: 'Ricerca Specie',
          });

          return (
            <div className={classes.container}>

              {renderInput({
                fullWidth: true,
                classes,
                InputProps: { onBlur, onFocus },
                InputLabelProps: getLabelProps({ shrink: true }),
                inputProps,
                ref: node => {
                  popperNode = node;
                },
              },clearSelection)}

              <Popper open={isOpen} anchorEl={popperNode}>
                <div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
                  <Paper
                    square
                    style={{ marginTop: 8, width: popperNode ? popperNode.clientWidth : undefined }}
                  >
                    {getSuggestions(inputValue, specie).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion.codpign }),
                        highlightedIndex,
                        selectedItem,
                        clearSelection,
                      }),
                    )}
                  </Paper>
                </div>
              </Popper>
            </div>
          );
        }}
      </Downshift>
    </div>
  );
}


export default InputSpecie;
