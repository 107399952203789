const initState = {
  specie:[],
  loader: false,
  layerLoading:false,
  layer: [],
  numeroSegnalazione: '',
  data:null,
}



  export function mapReducer(state = initState, action) {
    switch (action.type) {
        case 'ADD_SPECIE_TOT':
          return{...state, specie: [action.specie]}
        case 'DELETE_SPECIE_TOT':
        return{ specie: []}
        case 'SPECIE_IS_LOADING':
            return{...state, loader: action.isLoading}
        case 'SPECIE_HAS_ERRORED':
            return action.hasErrored;
        case 'ADD_LAYER_TOT':
        console.log(action.specie);
              return{
                ...state,
                 layer:action.specie,
                 numeroSegnalazione: action.specie.length
              }
        case 'LAYER_IS_LOADING':
                return{...state, layerLoading: action.isLoading}
        case 'LAYER_HAS_ERRORED':
                console.log("errore");
        case 'SEGN_SUCCESS':
                return{...state, data: action.payload}
        case 'SEGN_FAIL':
                console.log("segn fail");

        case 'DOWN_FAIL':
        console.log('download failed');
        case 'DOWN_SUCCESS':
        console.log('download success')
        default:
          return state;
    }
}
