const initState = {
  storic:[],
  storicLoading: false,
  storicError: null,
  formSuccess: null,
  formFail:null,
  notifiche: null,
}




  export function tableReducer(state = initState, action) {
    switch (action.type) {
        case 'STORIC_LOADING':
          return{
            ...state,
             storicLoading: true
           }
        case 'STORIC_SUCCESS':
         return{
           ...state,
            storic: action.payload,
            storicLoading:false
          }
      case 'STORIC_FAIL':
         return{
           ...state,
            storicLoading:false,
            storicError: action.payload
          }
      case 'POST_SUCCESS':
         return{
            ...state,
           formSuccess: 'Segnalazione avvenuta con successo'
           }
      case 'POST_FAIL':
           return{
            ...state,
            formFail: action.payload
            }
      case 'NOTIFICA_SUCCESS':
            return{
             ...state,
             notifiche: action.payload.da_revisionare
             }
    case 'DELETE_MESSAGE':
             return{
            ...state,
            formSuccess: null,
            formFail: null,
            }
      case 'NOTIFICA_FAIL':
            console.log(action.payload);
        default:
          return state;
    }
}
