import { mapReducer } from './mapReducer';
import { authReducer } from './authReducer';

import {tableReducer, formReducer} from './tableReducer';
import {combineReducers} from 'redux';


const rootReducer = combineReducers({
  mappa:mapReducer,
  table:tableReducer,
  auth: authReducer
})

export default rootReducer;
