


//tabella storic amministratori

export const STORIC_LOADING ='STORIC_LOADING';
export const STORIC_SUCCESS ='STORIC_SUCCESS';
export const STORIC_FAIL ='STORIC_FAIL';

export const loadStoricTot =(pagination, getState)=> dispatch =>{

  dispatch({type: STORIC_LOADING});
  let tok = getState().auth.token;

  fetch(`${process.env.REACT_APP_ENDPOINT}/api/storic/?format=json&limit=20&offset=20`, {
        headers: {
          'Authorization': `JWT ${tok}`
        }
      })
      .then(response =>
        response.json().then(json => ({
          status:response.status,
          json
            })
        ))
      .then(
        ({ status, json }) => {
        if( status >= 200 && status < 300) {
            dispatch({
              type: STORIC_SUCCESS,
              payload: json
             });
            }
        if (status >= 400 ) {
          console.log(json);
            dispatch({
                type: STORIC_FAIL,
                payload:json.non_field_errors[0]
              })
            }
          }
      )
  .catch(err =>{
    console.log(err.response);
    dispatch({
      type: POST_FAIL,
      payload: 'errore'
    })
  })
}



//datagrid elimina row

export const DELETE_SUCCESS ='DELETE_SUCCESS';
export const DELETE_FAIL ='DELETE_FAIL';

export const deleteSegnalazione =(seq)=> dispatch =>{

  let token = localStorage.token;

  fetch(`${process.env.REACT_APP_ENDPOINT}/api/storic/${seq}/`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${token}`
        }
      })
      .then(response =>
        response.json().then(json => ({
          status:response.status,
          json
            })
        ))
      .then(
        ({ status, json }) => {
        if( status >= 200 && status < 300) {
            dispatch({
              type: DELETE_SUCCESS,
              payload: json
             });
            }
        if (status >= 400 ) {
          console.log(json);
            dispatch({
                type: DELETE_FAIL,
                payload:json.non_field_errors[0]
              })
            }
          }
      )
  .catch(err =>{
    console.log(err);
    dispatch({
      type: DELETE_FAIL,
      payload:err
    })
  })
}


//datagrid modifica row

export const UPDATE_SUCCESS ='UPDATE_SUCCESS';
export const UPDATE_FAIL ='UPDATE_FAIL';

export const updateSegnalazione =(newData)=> dispatch =>{ 

let token = localStorage.token;
let seq = newData.seq;

let body = JSON.stringify(newData);
  fetch(`${process.env.REACT_APP_ENDPOINT}/api/storic/${seq}/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `JWT ${token}`
        },
        body
      })
      .then(response =>
        response.json().then(json => ({
          status:response.status,
          json
            })
        ))
      .then(
        ({ status, json }) => {
        if( status >= 200 && status < 300) {
            dispatch({
              type: UPDATE_SUCCESS,
              payload: json
             });
            }
        if (status >= 400 ) {
            dispatch({
                type: UPDATE_FAIL,
                payload:json.non_field_errors[0]
              })
            }
          }
      )
  .catch(err =>{
    console.log(err.response);
    dispatch({
      type: UPDATE_FAIL,
      payload:err
    })
  })
}




//form inserimento dati

export const POST_SUCCESS ='POST_SUCCESS';
export const POST_FAIL ='POST_FAIL';

export const DELETE_MESSAGE = 'DELETE_MESSAGE'

export const postSegnalazione =(dati)=> dispatch =>{
  const body = JSON.stringify(dati);

  let token = localStorage.token;
  fetch(`${process.env.REACT_APP_ENDPOINT}/api/storic/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `jwt ${token}`
        },
        body
      })
      .then(response =>
        response.json().then(json => ({
          status:response.status,
          json
            })
        ))
      .then(
        ({ status, json }) => {
        if( status >= 200 && status < 300) {
            dispatch({
              type: POST_SUCCESS,
              payload: json
             });
             setTimeout(() => {
               dispatch({
                 type: DELETE_MESSAGE,
                });
              }, 3000)
            }
        if (status >= 400 ) {
          console.log(json);
            dispatch({
                type: POST_FAIL,
                payload:json.codpign[0]
              });
              setTimeout(() => {
                dispatch({
                  type: DELETE_MESSAGE,
                 });
               }, 3000)
            }
          }
      )
  .catch(err =>{
    console.log(err.response);
    dispatch({
      type: POST_FAIL,
      payload:'errore'
    })
  })
}






// notifiche

export const NOTIFICA_SUCCESS ='NOTIFICA_SUCCESS';
export const NOTIFICA_FAIL ='NOTIFICA_FAIL';

export const loadNotifiche =()=>(dispatch, getState) =>{

  let token = localStorage.token;
  let staff = getState().auth.staff;

  fetch(`${process.env.REACT_APP_ENDPOINT}/api/da_revisionare/`, {
        headers: {
          'Authorization': `jwt ${token}`
        }
      })
      .then(response =>
        response.json().then(json => ({
          status:response.status,
          json
            })
        ))
      .then(
        ({ status, json }) => {
        if( status >= 200 && status < 300) {
            dispatch({
              type: NOTIFICA_SUCCESS,
              payload: json
             });
            }
        if (status >= 400 ) {
            dispatch({
                type: NOTIFICA_FAIL,
                payload:json.non_field_errors[0]
              })
            }
          }
      )
  .catch(err =>{
    dispatch({
      type: NOTIFICA_FAIL,
      payload:err
    })
  })
}
