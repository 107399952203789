export function itemsHasErrored(bool) {
    return {
        type: 'SPECIE_HAS_ERRORED',
        hasErrored: bool
    };
}
export function itemsIsLoading(bool) {
    return {
        type: 'SPECIE_IS_LOADING',
        isLoading: bool
    };
}

export const addSpecie = specie => ({
  type:'ADD_SPECIE_TOT',
  specie,
});


export const addSpecieTot = (specie) =>{
  let specieReplace = specie.replace('/', '--');
    return (dispatch, getState) => {
        dispatch(itemsIsLoading(true));
        fetch(`${process.env.REACT_APP_ENDPOINT}/api/info_specie/${specieReplace}/?format=json`)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(itemsIsLoading(false));
                return response;
            })
            .then((response) => response.json())
            .then((items) => dispatch(addSpecie(items)))
            .catch(() => dispatch(itemsHasErrored(true)));
    };
}




// action mappa layer


export function layerHasErrored(bool) {
    return {
        type: 'LAYER_HAS_ERRORED',
        hasErrored: bool
    };
}
export function layerIsLoading(bool) {
    return {
        type: 'LAYER_IS_LOADING',
        isLoading: bool
    };
}

export const addLayer = specie => ({
  type:'ADD_LAYER_TOT',
  specie,
});



export const addLayerTot = (specie) =>{
  let specieReplace = specie.replace('/', '--');
    return (dispatch, getState) => {
        dispatch(layerIsLoading(true));
        fetch(`${process.env.REACT_APP_ENDPOINT}/api/map_view/${specieReplace}`)
            .then((response) => {
                if (!response.ok) {
                    throw Error(response.statusText);
                }
                dispatch(layerIsLoading(false));
              return response;
            })
            .then((response) => response.json())
            .then((items) => dispatch(addLayer(items)))
            .catch(() => dispatch(layerHasErrored(true)));
    };
}






export const SEGN_SUCCESS ='SEGN_SUCCESS';
export const SEGN_FAIL ='SEGN_FAIL';

export const mapSegnalazioni =()=>(dispatch, getState) =>{

  let tok = getState().auth.token;

  fetch(`${process.env.REACT_APP_ENDPOINT}/segnalazioni_quadrante/0001/02271/`, {
        headers: {
          'Authorization': `JWT ${tok}`
        }
      })
      .then(response =>
        response.json().then(json => ({
          status:response.status,
          json
            })
        ))
      .then(
        ({ status, json }) => {
        if( status >= 200 && status < 300) {
            dispatch({
              type: SEGN_SUCCESS,
              payload: json
             });
            }
        if (status >= 400 ) {
            dispatch({
                type: SEGN_FAIL,
                payload:json.non_field_errors[0]
              })
            }
          }
      )
  .catch(err =>{
    console.log(err.response);
    dispatch({
      type: SEGN_FAIL,
      payload:err
    })
  })
}





// download pdf
export const DOWN_SUCCESS ='DOWN_SUCCESS';
export const DOWN_FAIL ='DOWN_FAIL';

export const downloadOgu =(oguId)=>(dispatch) =>{

console.log(oguId, `${process.env.REACT_APP_ENDPOINT}/api/ogu/${oguId}/`);

  fetch(`${process.env.REACT_APP_ENDPOINT}/api/ogu/${oguId}`)
      .then(response =>
        response.json().then(json => ({
          status:response.status,
          json
            })
        ))
      .then(
        ({ status, json }) => {
        if( status >= 200 && status < 300) {
            dispatch({
              type: DOWN_SUCCESS,
             });
           }
        if (status >= 400 ) {
            dispatch({
                type: DOWN_FAIL,
                payload:json.non_field_errors[0]
              })
            }
          }
      )
  .catch(err =>{
    console.log(err);
    dispatch({
      type: DOWN_FAIL,
      payload:err
    })
  })
}
