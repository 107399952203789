import React, {Component} from 'react';
import Atlas from '../../iconLayer.png'
import DeckGL from '@deck.gl/react';
import {GeoJsonLayer} from '@deck.gl/layers';
import {IconLayer} from '@deck.gl/layers';
import {HeatmapLayer} from '@deck.gl/aggregation-layers';

import {StaticMap} from 'react-map-gl';
import {NavigationControl} from 'react-map-gl';

import Download from './Download'
import DataMap from './MapGrid'

import Tooltip from '@material-ui/core/Tooltip';


import Fab from '@material-ui/core/Fab';
import MapIcon from '@material-ui/icons/Map';
import EcoIcon from '@material-ui/icons/Eco';


import basemap from './basemap.json'
import areebase from './areebase.json'
import heatmap from './heatmap_test.json'
import province from './province.json'

import {isMobile} from 'react-device-detect';



import {addSpecieTot} from '../store/actions/mapAction'
import {connect} from 'react-redux'


const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

// Initial viewport settings
let initialViewState;
let sizeScale;

if(isMobile){
   initialViewState = {
    longitude: 10.106366,
    latitude: 45.761775,
    zoom: 7,
    pitch: 0,
    bearing: 0,
  }
  sizeScale = 4;
}else{
   initialViewState = {
    longitude: 10.106366,
    latitude: 45.761775,
    zoom: 8,
    pitch: 0,
    bearing: 0,
  }
  sizeScale = 7;
}


// switch mappa

let nero = [5,5,5, 200];
let bianco = [230, 230, 230];
const red = [236,62,8];
const black = [0,0,0, 255];
// const yellowSatellite = [250, 222, 83];
// const bluSatellite = [56, 149, 245];

const purple = [174,71,200];
const green = [36,151,87];
// const white = [255,255,255];
const transparent = [0,0,0,0];

let isHovering = false;


class BaseMap extends Component {

  constructor(props) {
      super(props);
      this.state = {
         download:[],
         loading:false,
         clicked: null,
         hovered: null,
         color: nero,
         colorDati: black,
         mappa:"mapbox://styles/matteoseik/ck1g5svtl04t51cjmey91tngt",
         totalActive:false,
         totalSegnalazioni: null,
         abq: null,
         nome:null,
         numero:null,
         tot:false
        };
    }

   handleClick=(e)=> {
     const newColor = this.state.color === nero ? bianco : nero;
     const newColorDati = this.state.colorDati === black ? black : black;
     const newText = this.state.mappa === "mapbox://styles/matteoseik/ck1g5svtl04t51cjmey91tngt" ? "mapbox://styles/matteoseik/ckf83rs3q014g19ocjo7fnzl0" : "mapbox://styles/matteoseik/ck1g5svtl04t51cjmey91tngt";

       this.setState({
            ...this.state,
             mappa:newText,
             color:newColor,
             colorDati: newColorDati,
          });

      };

      fetchTotal = () => {
        fetch(`${process.env.REACT_APP_ENDPOINT}/api/ogu_count_species/`
        )
        .then((resp) => {
          return resp.json()
        })
        .then((data) => {
          this.setState({
              totalSegnalazioni:data
          });
        })
        .then(() => this.setState({ loading: false }))
        .catch((error) => {
          console.log(error, "catch the hoop")
        });
      }

     handleTotal = () => {
          if(this.state.tot == false){
            this.fetchTotal();
            this.setState({
              ...this.state,
              tot: true,
              loading:true
            })
          }else{
            this.setState({
              ...this.state,
              tot: false,
              totalSegnalazioni:null
            })
          }

    };


  render() {

    const {suggestions, livello, addSpecieTot, loader, numeroSegnalazione, auth} = this.props;
    const gridColor = [0,0,0,10];
    const gridColorSelected = [14,73,57,150];

    const ICON_MAPPING = {
      estinta: {x: 1080, y: 0, width: 270, height: 270, mask: true},
      cerchioVuoto: {x: 0, y: 0, width: 270, height: 270, mask: true},
      cerchioPieno: {x: 270, y: 0, width: 270, height: 270, mask: true},
      quadratoVuoto: {x: 540, y: 0, width: 270, height: 270, mask: true},
      quadratoPieno: {x: 810, y: 0, width: 270, height: 270, mask: true},
    };

const layers =[
  new GeoJsonLayer({
      id: 'geojson',
        data:basemap,
        opacity: 1,
        stroked: true,
        filled: true,
        extruded: false,
        wireframe: false,
        getLineColor: this.state.color,
        lineWidthScale: 80,
        pickable: true,
        getFillColor: (a)=>{
        if(a.properties.abq === this.state.clicked){
          return gridColorSelected;
        }else{
          return gridColor;
        }
      },
      updateTriggers: {
        getFillColor: [gridColor, gridColorSelected]
      },
      onHover: (a)=>{
      },
      onClick: ({object, x, y}) => {
        if(object != null && object.properties.abq != this.state.clicked){
          this.setState({
            download: object.properties,
            clicked:object.properties.abq
          })}else if(object.properties.abq == this.state.clicked){
            this.setState({
              clicked: null
              })
            }
        }
  }),
  new GeoJsonLayer({
      id: 'geojsonTotale',
        data:this.state.totalSegnalazioni,
        opacity: .4,
        stroked: true,
        filled: true,
        extruded: false,
        wireframe: false,
        getLineColor: this.state.color,
        lineWidthScale: 80,
        pickable: true,
        getFillColor: (a)=>{
          if(a.properties.numero_segnalazioni > 1200){
            return [14, 71, 63];
          }else if(a.properties.numero_segnalazioni >1000){
            return [38, 94, 86];
          }
          else if(a.properties.numero_segnalazioni >800){
            return [62, 118, 109];
          }
          else if(a.properties.numero_segnalazioni >600){
            return [86, 142, 132];

          }
          else if(a.properties.numero_segnalazioni >400){
            return [110, 166, 156];

          }
          else if(a.properties.numero_segnalazioni >200){
            return [134, 190, 179];
          }
          else if(a.properties.numero_segnalazioni >100){
          return [158, 214, 202];
          }
          else if(a.properties.numero_segnalazioni >0){
            return [182, 238, 226];
          }
      },
      updateTriggers: {
        getFillColor: [gridColor, gridColorSelected]
      },
      onHover: (a) =>{
        if(a.object != undefined){
          this.setState({
            abq: a.object.properties.abq,
            nome:a.object.properties.nome,
            numero:a.object.properties.numero_segnalazioni,
          })
        }
      }
  }),
  new GeoJsonLayer({
      id: 'geojson2',
        data:areebase,
        wireframe: true,
        filled:false,
        extruded: false,
        lineWidthScale: 2,
        lineWidthMinPixels: 2,
        getLineColor:this.state.color,
  }),
  new GeoJsonLayer({
      id: 'geojson3',
        data:province,
        wireframe: true,
        opacity:.8,
        filled:false,
        extruded: false,
        lineWidthScale: 1.6,
        lineWidthMinPixels: 1.6,
        getLineColor:[14, 71, 63, 255],
  }),

  new IconLayer({
    id: 'icon-layer',
    data:livello,
    pickable: true,
    iconAtlas: `${Atlas}`,
    iconMapping: ICON_MAPPING,
    getIcon: (d)=> {
    let tipo = d.tipo_segnalazioni[0];
      if(tipo === 'EX'){return "estinto";}
      if(tipo === 'HB'){return "quadratoPieno";}
      if(tipo === 'OX'){return "cerchioPieno";}
      if(tipo === 'ER'){return "quadratoPieno";}
      if(tipo === 'ES'){return "quadratoVuoto";}
      if(tipo === 'SR'){return "cerchioVuoto";}
      if(tipo === 'SA'){return "cerchioVuoto";}

      if(tipo === 'WEX'){return "cerchioPieno";}
      if(tipo === 'WHB'){return "quadratoPieno";}
      if(tipo === 'WOX'){return "cerchioPieno";}
      if(tipo === 'WER'){return "quadratoPieno";}
      if(tipo === 'WES'){return "quadratoVuoto";}
      if(tipo === 'WSR'){return "cerchioVuoto";}
      if(tipo === 'WSA'){return "cerchioVuoto";}

    },
    sizeScale: sizeScale,
    getPosition: d => d.coordinates,
    getSize: d => 4,
    getColor: (d) =>{
      let tipo = d.tipo_segnalazioni[0];
      if(tipo === 'EX'){return this.state.colorDati;}
      if(tipo === 'HB'){return this.state.colorDati;}
      if(tipo === 'OX'){return this.state.colorDati;}
      if(tipo === 'ER'){return red;}
      if(tipo === 'ES'){return red;}
      if(tipo === 'SR'){return this.state.colorDati;}
      if(tipo === 'SA'){return red;}

      if(tipo === 'WEX'){return this.state.colorDati;}
      if(tipo === 'WHB'){return purple;}
      if(tipo === 'WOX'){return purple;}
      if(tipo === 'WER'){return green;}
      if(tipo === 'WES'){return green;}
      if(tipo === 'WSR'){return purple;}
      if(tipo === 'WSA'){return green;}
  }
})
];

    return (
<div>

<DeckGL
        initialViewState={initialViewState}
        viewState={this.state.viewport}
        controller={true}
        layers={layers}
        height='100%'
        width='100%'
        getCursor={({isDragging}) => isDragging ? 'grabbing' : (isHovering ? 'pointer' : 'grab')}
      >

        <StaticMap
         mapboxApiAccessToken={MAPBOX_ACCESS_TOKEN}
         mapStyle={this.state.mappa}
          >

          <div className='navigationControl'>
            <NavigationControl onViewportChange={viewport => this.setState({ viewport })} />
          </div>

       </StaticMap>


</DeckGL>


{auth ? (<div className='datamap'>
  <DataMap />
</div>): (null)}


{this.state.totalSegnalazioni ? (<div className='tooltipContainer'>
  <div className='tooltipTotale'>
  <ul>
    <li><span>ABQ: </span>{this.state.abq}</li>
    <li><span>NOME: </span>{this.state.nome}</li>
    <li><span>N°SPECIE: </span>{this.state.numero}</li>
  </ul>
</div>
</div>
): (null)}


<div className='downloadBtn'>
  <Download ogu={this.state.download} clicked={this.state.clicked}/>
</div>

<div className='layerBtn'>
<Tooltip className='tooltip-left' title="Cambia basemap" placement='left'>
  <Fab onClick={this.handleClick} size="medium" color="primary" aria-label="add">
    <MapIcon  />
  </Fab>
  </Tooltip>
</div>

<div className={'totalBtn ' + this.state.tot}>
<Tooltip className='tooltip-left' title="totale specie" placement='left'>
  <Fab onClick={this.handleTotal} size="medium" color="primary" aria-label="add">
    <EcoIcon />
  </Fab>
  </Tooltip>
</div>


{(loader) ? (<div style={{position: 'absolute', right: '48%', top: '50%'}}>
  <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>): null}

{(this.state.loading) ? (<div style={{position: 'absolute', right: '48%', top: '50%'}}>
  <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>): null}

</div>
    );
  }
}

const mapDispatchToProps =(dispatch)=>{
  return{
    addSpecieTot: (specie)=> dispatch(addSpecieTot(specie))
  }
}
const mapStateToprops=(state)=>{
  return{
    suggestions:state.mappa.specie,
    livello:state.mappa.layer,
    loader:state.mappa.layerLoading,
    numeroSegnalazione: state.mappa.numeroSegnalazione,
    auth: state.auth.isAuthenticated,
  }
}

export default connect(mapStateToprops, mapDispatchToProps)(BaseMap)
