import React, {Component} from 'react';
import InputSpecie from './InputSpecie';
import InfoSpecie from './Card';
import Legenda from './Legenda';
import Download from './Download'


import BaseMap from './BaseMap'
import {addSpecieTot} from '../store/actions/mapAction'
import {addLayerTot} from '../store/actions/mapAction'

import {connect} from 'react-redux'




class Map extends Component {

  render() {
    const {suggestions, addSpecieTot, addLayerTot} = this.props;

    return (
      <div >
        <BaseMap />


        <div className='input inputSpecie'>
          <InputSpecie addSpecieTot={addSpecieTot} addLayerTot={addLayerTot} suggestions={suggestions}/>
        </div>

        <div className='infospecie' >
          <InfoSpecie />
        </div>

        <div className='legenda'>
          <Legenda />
        </div>

        <div className='credits'>
          MADE BY <a href='https://matteosacchi.com/' target='_blank'>MATTEO SACCHI & GIOVANNI BONELLI</a>
        </div>

</div>
    );
  }
}

const mapDispatchToProps =(dispatch)=>{
  return{
    addSpecieTot: (specie)=> dispatch(addSpecieTot(specie)),
    addLayerTot: (specie)=> dispatch(addLayerTot(specie))
  }
}
const mapStateToprops=(state)=>{
  return{
    suggestions:state.mappa.specie,
  }
}

export default connect(mapStateToprops, mapDispatchToProps)(Map)
