import React  from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Img from '../outdoor.png'


import {connect} from 'react-redux'
import { sendMail } from '../components/store/actions/authAction'

import { useFormik } from 'formik';
import * as Yup from 'yup';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://www.floralpinabergamasca.net/">
        FAB
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  img:{
    position:'absolute',
    width:'100%',
    height:'100vh',
    opacity:.5,
    top:0,
    left:0,
    zIndex:-1,
  },
  bkg:{
    backgroundColor:'#719993',
    position:'absolute',
    zIndex: -2,
    width:'100%',
    height:'100vh',
    top:0,
    left:0,
  },
  link:{
    marginTop:15
  }
}));

function Emailrestore({sendMail, success, fail}) {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Indirizzo mail non valido`')
        .required('Required')
    }),
    onSubmit: values => {
      sendMail(values);
      formik.handleReset();
    },
  });

if(success){
  return (
    <Container className='containerCard' component="main" maxWidth="xs">
    <img src={Img} className={classes.img} alt=' '/>
    <div className={classes.bkg}></div>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
        {success}
        </Typography>
      </div>
      <Grid className={classes.link} container>
        <Grid item xs>
          <Link href="/Signin" variant="body2" color="primary">
            Torna alla pagina di Login
          </Link>
        </Grid>
      </Grid>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>

  );
}else{
  return (
    <Container className='containerCard' component="main" maxWidth="xs">
    <img src={Img} className={classes.img} alt=' '/>
    <div className={classes.bkg}></div>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
        Inserisci la mail associata al tuo account
        </Typography>
        <form onSubmit={formik.handleSubmit} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="indirizzo email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
    <div>{formik.errors.email}</div>
  ) : null}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Invia
          </Button>

        </form>
      </div>
      <Grid container>
        <Grid item xs>
          <Link href="/Signin" variant="body2" color="primary">
            Torna alla pagina di Login
          </Link>
        </Grid>
      </Grid>

      { fail ? ( <Typography variant="body2" style={{color: "red"}}>
              {fail}</Typography>): null}

      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>

  );
}



}



const mapStateToprops=(state)=>{
  return{
    success: state.auth.mailSuccess,
    fail: state.auth.mailFail
  }
}

export default connect(mapStateToprops, {sendMail})(Emailrestore)
