import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Img from '../outdoor.png'
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { login } from '../components/store/actions/authAction'

import { useFormik } from 'formik';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://www.floralpinabergamasca.net/">
        FAB
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  img:{
    position:'absolute',
    width:'100%',
    height:'100vh',
    opacity:.5,
    top:0,
    left:0,
    zIndex:-1,
  },
  bkg:{
    backgroundColor:'#719993',
    position:'absolute',
    zIndex: -2,
    width:'100%',
    height:'100vh',
    top:0,
    left:0,
  },
  error:{
    color:'red',
    fontSize:16
  }
}));

 function SignIn(state) {
  const classes = useStyles();


  const formik = useFormik({
    initialValues: {
      email: '',
      password:''
    },
    onSubmit: values => {
      const user ={
        username:values.email,
        password:values.password
      }
      state.login(user);
    },
  });

  if(state.isAuthenticated){
    return <Redirect to='/' />;
  }

  return (

    <Container className='containerCard' component="main" maxWidth="xs">
    <img src={Img} className={classes.img} alt=''/>
    <div className={classes.bkg}></div>
      <CssBaseline />
      <div className={classes.paper}>

      { state.error ? (  <Typography component="h1" className={classes.error}>
          {state.error}
        </Typography>): null
      }

        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Accedi
        </Typography>
        <form onSubmit={formik.handleSubmit} className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="indirizzo email"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={formik.handleChange}
            value={formik.values.email}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="password"
            onChange={formik.handleChange}
            value={formik.values.password}
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Accedi
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/Restore" variant="body2" color="primary">
                Recupero Password
              </Link>
            </Grid>
            <Grid item>
              <Link href="/signup" variant="body2">
                Non hai un account? Registrati
              </Link>
            </Grid>
          </Grid>

        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

const mapStateToProps = state =>({
isAuthenticated: state.auth.isAuthenticated,
error: state.auth.loginError
});

export default connect(mapStateToProps,{login})(SignIn);
