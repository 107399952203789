import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root:{
    padding:'5px',
  },
  card: {
    minWidth: 285,
    padding:'0px',
  },
  title: {
    fontSize: '14px',
    marginBottom:'5px',
  }
});

export default function SelectQuadrante({ogu, clicked}) {
  const classes = useStyles();

  if(ogu.nome != null && clicked != null ){
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.title} color="primary">
            ABQ — {ogu.abq}
          </Typography>
          <Typography variant="h5" component="h2">
          {ogu.nome}
          </Typography>
        </CardContent>
      </Card>
    )
  }else{
    return(<div></div>)
  }
}
