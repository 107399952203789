import React, {  useEffect } from 'react';
import MaterialTable from 'material-table';
import TablePagination from '@material-ui/core/TablePagination';
import Container from '@material-ui/core/Container';

import {loadStoricTot, updateSegnalazione, deleteSegnalazione} from '../store/actions/tableAction'
import {connect} from 'react-redux'



 function Datagrid({loadStoricTot, storic, deleteSegnalazione, updateSegnalazione, staff}) {


   const [count, setCount] = React.useState({
     counter: 0,
   });
  const [state, setState] = React.useState({
    columns: [
      { title: 'ACCETTATA', field: 'accettata', type:'boolean', cellStyle: rowData =>((rowData)?({color:'#1d5e55'}):({color:'#ec5208'}))},
      { title: 'REVISIONATA', field: 'revisionata', type:'boolean', cellStyle: rowData =>((rowData)?({color:'#1d5e55'}):({color:'#ec5208'}))},
      { title: 'CODPIGN', field: 'codpign' },
      { title: 'SPECIE', field: 'gen_spe_aut', cellStyle: {
       minWidth: 350, backgroundColor: '#fffed2'
     },},
      { title: 'ABQ', field: 'abq', type:'numeric' },
      { title: 'TIPO', field: 'tipo' },
      { title: 'UTENTE', field: 'owner' },
      { title: 'PROVENIENZA DATI', field: 'prov_dat'},
      { title: 'DATA_AGG', field: 'data_agg', type: 'date' },
      { title: 'DATA', field: 'data', type: 'date' },
      { title: 'ESPOSIZIONE', field: 'esp' },

      { title: 'LOCO', field: 'loco', cellStyle: {
       minWidth: 500, backgroundColor: '#d6ffec'
     },},
     { title: 'PROV', field: 'prov' },
     { title: 'COMUNE', field: 'comune', cellStyle: {
      minWidth: 250
    },},
      { title: 'ANNO', field: 'anno', type:'numeric' },
      { title: 'SOTTOREGIONE FISICA', field: 'sf' },

      { title: 'QUOTA', field: 'quota' },
      { title: 'QUOTA_MAX', field: 'quota_max', type:'numeric' },
      { title: 'EST_DEC', field: 'est_dec' },
      { title: 'NORD_DEC', field: 'nord_dec' },
      { title: 'SUOLO', field: 'suolo' },
      { title: 'DETERM', field: 'determ' },
      { title: 'LEGIT', field: 'legit' },
      { title: 'DATA_DET', field: 'data_det' },
      { title: 'NS', field: 'ns' },
      { title: 'UBICAZIONE', field: 'ubicazione' },
      { title: 'N_INV', field: 'n_inv',  type:'numeric' },
      { title: 'N_INGR_HB', field: 'n_ingr_hb',  type:'numeric' },
      { title: 'SEGN', field: 'segn', cellStyle: {
       minWidth: 250
     },},
      { title: 'SEQ', field: 'seq', type:'numeric', editable: 'never' },

    ],
    data: [{}],
    count:0,
    next:'',
    previous:'',
    page: 0,
    rowsPerPage: 20,
    pageCount:0,
  });


  useEffect(() => {
    loadData();
  },[count]);



const loadData = async () =>{
const token = localStorage.token;
  if(token){
    const response = await fetch(`${process.env.REACT_APP_ENDPOINT}/api/storic/?format=json&limit=${state.rowsPerPage}&offset=${state.pageCount}`, {
        headers: {
          Authorization: `jwt ${token}`
          }
        }).catch(err => {
      console.log(err.response.data);
    });
      const dataa = await response.json();
      const data = dataa.results;
      const next = dataa.next;
      const previous = dataa.previous;
      const count = dataa.count;
       setState({
        ...state,
         data,
         next,
         previous,
         count,
      });
  }
}

const handleChange =(newData)=>{
updateSegnalazione(newData);
}

const handleDelete = (oldData) => {
let seq = oldData.seq;
deleteSegnalazione(seq);
}

const handleDeleteTwo = (seq) => {
deleteSegnalazione(seq);
}


  const handleChangePage = (event, newPage) => {
    let pageCount = state.pageCount;
    let page = state.page;
    if(newPage > state.page){
      pageCount = pageCount + 20;
      page = page+1;
    }
    if(newPage < state.page){
      pageCount = pageCount - 20;
      page = page-1;
    }

    setState({
      ...state,
      pageCount,
      page,
    });
    setCount({
      counter: pageCount,
    });

  }


  const attachScroller = (scroller) => {
    this._scroller = React.findDOMNode(scroller);
  };

if(staff){
  return (
    <Container className='storic-page' maxWidth="xl" >

    <MaterialTable
      title="Storico dati"
      columns={state.columns}
      data={state.data}
      options={{
          pageSize: 20,
          tableLayout:'auto',
          headerStyle: {
          color: '#1d5e55',
        },

      }}
      localization={{ body: { editRow: { deleteText: 'Conferma eliminazione segnalazione' } } }}

      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              if (oldData) {
                setState(prevState => {
                  const data = [...prevState.data];
                  let ind;
                  var filteredObj = data.find(function(item, i){
                    if(item.seq === oldData.seq){
                      ind = i;
                      return i;
                    }
                  });
                  data[ind] = newData;
                  return { ...prevState, data };
                });
                handleChange(newData);

              }
            }, 600);
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            setTimeout(() => {
              resolve();
              setState(prevState => {
                const data = [...prevState.data];
                let ind;
                var filteredObj = data.find(function(item, i){
                  if(item.seq === oldData.seq){
                    ind = i;
                    return i;
                  }
                });
                data.splice(ind, 1);
                return { ...prevState, data };
              });
            }, 600);
            handleDelete(oldData);
          }),
      }}
      components = {{
     Pagination : props =>
       <TablePagination
         onChangePage={handleChangePage}
         count={state.count}
         page={state.page}
         rowsPerPage={state.rowsPerPage}
         rowsPerPageOptions={[20]}
        />
      }}
    />
    </ Container>
  );
}else{
  return(
    <Container maxWidth="xl" >

    <MaterialTable
      title="Storico dati"
      columns={state.columns}
      data={state.data}
      options={{
          pageSize: 20,
          tableLayout:'auto',
          headerStyle: {
           color: '#1d5e55',
         }
      }}
            localization={{ body: { editRow: { deleteText: 'Conferma eliminazione segnalazione' } } }}


            actions={[
              oldData => ({
                icon: 'delete',
                tooltip: 'cancella segnalazione',
                onClick: (e, rowData) =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      resolve();
                      setState(prevState => {
                        const data = [...prevState.data];
                        handleDeleteTwo(rowData.seq);
                        let ind;
                        data.splice(ind, 1);
                        return { ...prevState, data };
                      });
                    }, 600);
                  }),
                disabled: oldData.accettata
              })
            ]}


      components = {{
     Pagination : props =>
       <TablePagination
         onChangePage={handleChangePage}
         count={state.count}
         page={state.page}
         rowsPerPage={state.rowsPerPage}
         rowsPerPageOptions={[20]}
        />
      }}
    />
    </ Container>
  )
}


}



const mapDispatchToProps =(dispatch)=>{
  return{
    loadStoricTot: (pagination)=> dispatch(loadStoricTot(pagination)),
    deleteSegnalazione: (seq)=> dispatch(deleteSegnalazione(seq)),
    updateSegnalazione: (seq)=> dispatch(updateSegnalazione(seq))
  }
}
const mapStateToprops=(state)=>{
  return{
    storic:state.table.storic,
    staff: state.auth.staff
  }
}

export default connect(mapStateToprops, mapDispatchToProps)(Datagrid)
