import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import GetAppIcon from '@material-ui/icons/GetApp';

import { connect } from 'react-redux';
import {downloadOgu} from '../store/actions/mapAction'

import Button from '@material-ui/core/Button';
import SelectQuadrante from './SelectQuadrante';
import Tooltip from '@material-ui/core/Tooltip';


const useStyles = makeStyles(theme => ({
  fab: {
    position:'absolute',
    right: 5,
    top: 120
  }
}));



function Download({ogu, clicked, downloadOgu}) {
  const classes = useStyles();

  const oguId = ogu.abq
  const handleClicktwo = () => {
    if(clicked){
      // downloadOgu(oguId);
      fetch(`https://app.floralpinabergamasca.net/api/ogu/${oguId}`)
      .then(response => response.blob())
    .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = `lista-${oguId}.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
    });

      }
    };

  return (

    <div>
    <Tooltip className='tooltip-left' title="elenco specie per quadrante selezionato" placement='left'>
      <Fab onClick={handleClicktwo} size="medium" color="primary" aria-label="add" className={classes.fab}>
        <GetAppIcon ogu={ogu}  />
      </Fab>
      </Tooltip>


      <div className='quadrante'>
           <SelectQuadrante ogu={ogu} clicked={clicked} />
      </div>
    </div>

  );
}


const mapStateToprops=(state)=>{
  return{
  state,
  }
}

export default connect (null, {downloadOgu})(Download);
