import React, {Component} from 'react';
import Navbar from '../components/Navbar'
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import About from '../components/About'
import Signin from '../components/Signin'
import Signup from '../components/Signup'
import Input from '../components/dashboard/Insert'
import Storic from '../components/dashboard/Datagrid'
import Map from '../components/map/Map'
import NoMatch from '../components/404'
import Restore from '../components/emailRestore'
import RestorePsw from '../components/RestorePsw'
import PrivateRoute from '../components/common/PrivateRoute'



class AppRouterMobile extends Component {

  render(){
    return (
      <div className="App">
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route exact path='/' component={Map} />
          <Route path='/About' component={About} />
          <Route component={NoMatch} />
        </Switch>
      </BrowserRouter>
      </div>
    )
  }
}
          // <PrivateRoute path='/Dashboard' component={Dashboard} />

export default AppRouterMobile;
