import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';

import Estinta from '../../estinta.png'

import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 450,
  },
  cardInner:{
    justifyContent: 'space-between',
    padding: 5,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  title: {
    fontSize: 16,
    opacity:1,
    marginLeft:10
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  list: {
   width: '100%',
   maxWidth: 450,
 },

}));

export default function Legenda() {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card className='legenda-container'>

      <CardActions className={classes.cardInner}>

          <Typography className={classes.title} color="primary" gutterBottom>
            Legenda
          </Typography>

        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          size='small'
        >
          <ExpandMoreIcon />
        </IconButton>


      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>

      <AppBar className='tab-header' position="static">
        <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
          <Tab label="Segnalazioni" {...a11yProps(0)} />
          <Tab label="Segnalazioni W" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
          <CardContent>
            <List className={classes.list}>
        <ListItem>
          <ListItemAvatar>
              <div id='hb'></div>
          </ListItemAvatar>
          <ListItemText primary="dato di erbario recente" secondary="anno > 1968" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
          <div id='ox'></div>
          </ListItemAvatar>
          <ListItemText primary="osservazione di campagna" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
          <div id='er'></div>
          </ListItemAvatar>
          <ListItemText primary="dato erbario storico rivisto" secondary="anno < 1968" />
        </ListItem>

        <ListItem>
          <ListItemAvatar>
              <div id='es'></div>
          </ListItemAvatar>
          <ListItemText primary="dato erbario storico non rivisto" secondary="anno < 1968" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
          <div id='sr'></div>
          </ListItemAvatar>
          <ListItemText primary="segnalazione bibliografica recente" secondary="anno > 1968" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
          <div id='sa'></div>
          </ListItemAvatar>
          <ListItemText primary="segnalazione bibliografica antica" secondary="anno < 1968" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
          <div id='ex'><img src={Estinta} /></div>
          </ListItemAvatar>
          <ListItemText primary="taxon estinto nel quadrante" />
        </ListItem>
            </List>
          </CardContent>
    </TabPanel>

      <TabPanel value={value} index={1}>
          <CardContent>
            <List className={classes.list}>
        <ListItem>
          <ListItemAvatar>
              <div id='whb'></div>
          </ListItemAvatar>
          <ListItemText primary="dato di erbario recente" secondary="anno > 1968" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
          <div id='wox'></div>
          </ListItemAvatar>
          <ListItemText primary="osservazione di campagna" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
          <div id='wer'></div>
          </ListItemAvatar>
          <ListItemText primary="dato erbario storico rivisto" secondary="anno < 1968" />
        </ListItem>

        <ListItem>
          <ListItemAvatar>
              <div id='wes'></div>
          </ListItemAvatar>
          <ListItemText primary="dato erbario storico non rivisto" secondary="anno < 1968" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
          <div id='wsr'></div>
          </ListItemAvatar>
          <ListItemText primary="segnalazione bibliografica recente" secondary="anno > 1968" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
          <div id='wsa'></div>
          </ListItemAvatar>
          <ListItemText primary="segnalazione bibliografica antica" secondary="anno < 1968" />
        </ListItem>
        <ListItem>
          <ListItemAvatar>
          <div id='ex'><img src={Estinta} /></div>
          </ListItemAvatar>
          <ListItemText primary="taxon estinto nel quadrante" />
        </ListItem>
            </List>
          </CardContent>
      </TabPanel>

      </Collapse>
    </Card>
  );
}
