import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Img from '../outdoor.png'
import { connect } from 'react-redux';

import { register } from '../components/store/actions/authAction'

import { useFormik } from 'formik';
import * as Yup from 'yup';




function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://www.floralpinabergamasca.net/">
FAB      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  img:{
    position:'absolute',
    width:'100%',
    height:'100vh',
    opacity:.5,
    top:0,
    left:0,
    zIndex:-1,
  },
  bkg:{
    backgroundColor:'#719993',
    position:'absolute',
    zIndex: -2,
    width:'100%',
    height:'100vh',
    top:0,
    left:0,
  },
  error:{
    color:'red',
    fontSize:16
  }
}));


function SignUp(state) {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password:'',
      passwordConfirm:''
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .max(15, 'deve essere meno di 15 caratteri')
        .required('richiesto'),
      lastName: Yup.string()
        .max(20, 'deve essre meno di 20 caratteri')
        .required(''),
      email: Yup.string()
        .email('indirizzo mail non valido`')
        .required('richiesto'),
      password: Yup.string()
        .required('inserire la password')
        .min(8, 'password troppo breve - deve essere almeno 8 caratteri')
        .matches(/[a-zA-Z]/, 'La password deve contere solo lettere latine'),
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], "Le password non coincidono")
        .required('confermare la password')
    }),
    onSubmit: values => {
      const newUser ={
        first_name: values.firstName,
        last_name: values.lastName,
        email:values.email,
        password:values.password
      }
      state.register(newUser);
      formik.handleReset();

    },
  });

// if(state.isAuthenticated){
//   return <Redirect to='/signup' />
// }

if(state.registerSuccess){
  return(
    <Container className='containerCard' component="main" maxWidth="xs">
    <img src={Img} className={classes.img} alt=''/>
    <div className={classes.bkg}></div>

      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registrazione avvenuta con successo! accertati di riceverai la mail di conferma e poi potrai accedere al tuo profilo dalla pagina       <Link href="/signin" variant="h5">Login</Link>
        </Typography>

      <Box mt={5}>
        <Copyright />
      </Box>
      </div>
    </Container>
  );
}else {
  return (
    <Container className='containerCard' component="main" maxWidth="xs">
    <img src={Img} className={classes.img} alt=''/>
    <div className={classes.bkg}></div>

    {state.registerError ? (
      <Typography component="h1" className={classes.error}>
          {state.registerError}
      </Typography>
    ):null }

      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registrati
        </Typography>
        <form onSubmit={formik.handleSubmit} className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Nome"
                autoFocus
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.firstName}
              />

              {formik.touched.firstName && formik.errors.firstName ? (
                <div>{formik.errors.firstName}</div>
              ) : null}

            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Cognome"
                autoComplete="lname"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.lastName}
              />

              {formik.touched.lastName && formik.errors.lastName ? (
        <div>{formik.errors.lastName}</div>
      ) : null}

            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="email"
                autoComplete="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.touched.email && formik.errors.email ? (
        <div>{formik.errors.email}</div>
      ) : null}
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {formik.touched.password && formik.errors.password ? (
        <div>{formik.errors.password}</div>
      ) : null}
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="passwordConfirm"
                label="Conferma password"
                type="password"
                id="passwordConfirm"
                autoComplete="current-password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                value={formik.values.passwordConfirm}
              />
              {formik.touched.passwordConfirm && formik.errors.passwordConfirm ? (
        <div>{formik.errors.passwordConfirm}</div>
      ) : null}
            </Grid>

          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Registrati
          </Button>
          <Grid container justify="flex-end">
            <Grid item>
              <Link href="/signin" variant="body2">
                Sei già registrato? Accedi
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}

}

const mapStateToProps = state =>({
registerSuccess: state.auth.registerSuccess,
registerError: state.auth.registerError

});

export default connect(mapStateToProps,{register})(SignUp);
