import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import PersonIcon from '@material-ui/icons/Person';
import UserNav from './common/userNav';
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';

import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Img from '../logo_200x200.png'

import {isMobile} from 'react-device-detect';

import { NavLink} from 'react-router-dom'
import { connect } from 'react-redux';
import {logout} from './store/actions/authAction'


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    color:'white',
    '& > *': {
     margin: theme.spacing(1),
   },
  },
  bar:{
    color:'white',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },

  btn:{
    padding:"10px",
    color: 'white',
  },
  user:{
    padding:"5px 5px",
    color: 'white',
  },
  badge: {
   right: 0,
   top: 0,
 },
 navbar:{
   width:'auto'
 }
}));




const Map = React.forwardRef((props, ref) => <div ref={ref}><NavLink to="/" {...props} /></div>)
const Info = React.forwardRef((props, ref) => <div ref={ref}><NavLink to="/about" {...props} /></div>)
const Login = React.forwardRef((props, ref) => <div ref={ref}><NavLink to="/signin" {...props} /></div>)
const Signup = React.forwardRef((props, ref) => <div ref={ref}><NavLink to="/signup" {...props} /></div>)
const Insert = React.forwardRef((props, ref) => <div ref={ref}><NavLink to="/insert" {...props} /></div>)
const Storic = React.forwardRef((props, ref) => <div ref={ref}><NavLink to="/storico" {...props} /></div>)


function Navbar(state, props) {
  const { isAuthenticated, user, isLoading, staff } = state.auth;
  const { notifiche } = state.notifiche;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
  setAnchorEl(event.currentTarget);
};

const handleClose = () => {
  setAnchorEl(null);
};


  if(isMobile){
    return (
      <div className={classes.root}>
        <AppBar className='navbar-desktop' color='primary' position="static">
          <Toolbar>
            <Typography component={Map} className='logo'  color="secondary">
              FAB	• Atlante Digitale
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    );
  }


  if(isAuthenticated){
    return(
      <div className={classes.root}>
        <AppBar className='navbar-desktop' color='primary' position="static">
          <Toolbar>
          <img src={Img} className='logoImg' alt=''/>

            <Typography  variant="h6" color="secondary" className='logo-desktop'>
              FAB	• Atlante Digitale
            </Typography>
              <Button className={classes.btn} color='secondary' component={Map}>Mappa</Button>
              <Button className={classes.btn} color='secondary' component={Insert}>Segnalazioni</Button>
              <Button className={classes.btn} color='secondary' component={Storic}>Storico</Button>


              { staff ? (
                <div>
                  <IconButton
                   aria-label="account of current user"
                   aria-controls="menu-appbar"
                   aria-haspopup="true"
                   onClick={handleMenu}
                   color="inherit"
                    >

                    { notifiche ? (  <Badge className='badge' badgeContent={notifiche} max={999} color="secondary">
                      <PersonIcon color='secondary' className={classes.user} />
                      </Badge>):(
                        <PersonIcon color='secondary' className={classes.user} />
                      )}

                    </IconButton>
                    <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>{user}</MenuItem>
                    <MenuItem onClick={state.logout}>Logout</MenuItem>
                  </Menu>
                </div>
              ) : (
                <div>
                  <IconButton
                   aria-label="account of current user"
                   aria-controls="menu-appbar"
                   aria-haspopup="true"
                   onClick={handleMenu}
                   color="inherit"
                    >
                    <PersonIcon color='secondary' className={classes.user} />
                    </IconButton>
                    <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    open={open}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={handleClose}>{user}</MenuItem>
                    <MenuItem onClick={state.logout}>Logout</MenuItem>
                  </Menu>
                </div>
                )
              }

          </Toolbar>
        </AppBar>
      </div>
    );
  }else{
    return (
      <div className={classes.root}>
        <AppBar className='navbar-desktop' color='primary' position="static">
          <Toolbar>
          <img src={Img} className='logoImg' alt=''/>

          <Typography  variant="h6" color="secondary" className='logo-desktop'>
            FAB	• Atlante Digitale
          </Typography>
              <Button className={classes.btn} color='secondary' component={Map}>Mappa</Button>
              <Button className={classes.btn} color='secondary' component={Login}>Login</Button>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = state =>({
  auth: state.auth,
  staff: state.auth.staff,
  notifiche: state.table,
});

export default connect(mapStateToProps, {logout})(Navbar);
