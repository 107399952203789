import React, { useState, useEffect } from "react";
import ReactDOM from 'react-dom';
import './index.css';
import AppRouter from './routers/AppRouter';
import AppRouterMobile from './routers/AppRouterMobile';


import {isMobile} from 'react-device-detect';


import * as serviceWorker from './serviceWorker';
import {MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import thunk from "redux-thunk";
import { Provider } from 'react-redux'
import rootReducer from './components/store/reducers/rootReducer.js'
import { loadUser } from './components/store/actions/authAction'
import { loadNotifiche } from './components/store/actions/tableAction'


const store = createStore(rootReducer,composeWithDevTools(
  applyMiddleware(thunk),
  // other store enhancers if any
));




const theme = createMuiTheme({
  props: {
      MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette:{
    primary:{
      main: "#0e4939",
      light:"#719993"
    },
    secondary:{
      main:'#FFFFFF',
      light:'#F8F8F8',
      dark:'#EBEBEB'
    },
  },
  typography: {
    fontFamily: [
      'Helvetica_Now_Regular',
      '"Roboto"',
      'Arial',
      'sans-serif',
    ].join(','),
  }
})

function MainApp() {
  const [count, setCount] = useState(0);
    useEffect(() => {
      store.dispatch(loadUser());
      store.dispatch(loadNotifiche());
    });


    if (isMobile) {
      return(
          <Provider store={store}>
            <MuiThemeProvider theme={theme}>
                <AppRouterMobile />
            </MuiThemeProvider>
          </Provider>
        );
   }else{
     return(
           <Provider store={store}>
             <MuiThemeProvider theme={theme}>
                 <AppRouter/>
             </MuiThemeProvider>
           </Provider>
         );
     }
   }




ReactDOM.render(
    <MainApp />,
  document.getElementById('root'));


serviceWorker.unregister();
