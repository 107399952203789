import {
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  MAIL_FAIL,
  MAIL_SUCCESS,
  RESET_FAIL,
  RESET_SUCCESS
} from '../actions/authAction'

const initState = {
  token:localStorage.getItem('token'),
  isAuthenticated: null,
  registerSuccess: null,
  registerError: null,
  loginError: null,
  isLoading: false,
  user: null,
  staff: null,
  mailSuccess: null,
  mailFail: null,
  resetSuccess: null,
  resetFail: null,
}


export function authReducer(state = initState, action){
  switch(action.type){
    case USER_LOADING:
    return{
      ...state,
      isLoading:true
    };
    case USER_LOADED:
    localStorage.setItem('token', action.payload.token);
      return{
        ...state,
        isAuthenticated:true,
        isLoading:false,
        user:action.payload.user.username,
        staff: action.payload.user.is_staff,
      };
    case LOGIN_SUCCESS:
    localStorage.setItem('token', action.payload.token);
    return{
      ...state,
      user:action.payload.user.username,
      staff: action.payload.user.is_staff,
      isAuthenticated:true,
      loginError:null,
      registerError:null,
      isLoading:false,
      staff: action.payload.user.is_staff,

    }
    case REGISTER_SUCCESS:
      return{
        ...state,
        registerSuccess:true,
      };
    case LOGOUT_SUCCESS:
    localStorage.removeItem('token');
    return initState;
    case AUTH_ERROR:
    case LOGIN_FAIL:
    localStorage.removeItem('token');
    return{
      ...state,
      token:null,
      user:null,
      loginError: action.payload,
      isAuthenticated:false,
      isLoading:false,
      staff: null
    };
    case REGISTER_FAIL:
    localStorage.removeItem('token');
    return{
      ...state,
      token:null,
      user:null,
      registerError: action.payload,
      isAuthenticated:false,
      isLoading:false,
      staff: null
    };
    case RESET_SUCCESS:
    return{
      ...state,
      resetSuccess: 'Ripristino password avvenuto con successo'
    };
    case MAIL_SUCCESS:
    return{
      ...state,
      mailSuccess: 'Email inviata correttamente, controlla la tua casella di posta'
    };
    case RESET_FAIL:
    return{
      ...state,
      resetFail: 'Errore di ripristino password'
    };
    case MAIL_FAIL:
    return{
      ...state,
      mailFail: 'Problema di invio mail'
    };
    default:
      return state;
  }
}
