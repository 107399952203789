import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Fab from '@material-ui/core/Fab';
import MapTable from './MapTable'
import Tooltip from '@material-ui/core/Tooltip';
import StorageIcon from '@material-ui/icons/Storage';


const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fab: {
    position:'absolute',
    right: 5,
    top: 120
  },
  fullList: {
    width: 'auto',
  },
});

export default function DataMap() {
  const classes = useStyles();
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer =  (anchor, open) => (event) => {

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
    >


    <MapTable />

    </div>
  );

  return (
    <div>
      {['bottom'].map((anchor) => (
        <React.Fragment key={anchor}>
        <Tooltip className='tooltip-left' title="dati per specie" placement='left'>
        <Fab onClick={toggleDrawer(anchor, true)} size="medium" color="primary" aria-label="add" className={classes.fab}>
          <StorageIcon >{anchor}</StorageIcon>
        </Fab>
        </Tooltip>


          <SwipeableDrawer
          className='drawer'
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
