import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { ListItemText } from '@material-ui/core';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import {connect} from 'react-redux'


const useStyles = makeStyles({

  title: {
    fontSize: 14,
    opacity:0.9,
    marginBottom: 8,
  },
  pos: {
    marginBottom: 12,
    marginTop: 0,
    fontSize: 14,
  },
  sinonimi:{
  listStyleType:'none',
  margin:3
},
listaSinonimi:{
  fontSize:14,
},
listItem:{
padding:0
},
panel:{
  padding:0

}
});



const InfoSpecie =({specie, isLoading,numeroSegnalazione, deleteSpecieTot})=> {
  const classes = useStyles();

  if(isLoading == true){
    return(
        <Card className={classes.card}>
          <div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </Card>
      )
  }else if(specie){
      return (
          specie.map((spec, i)=>{
            return(
              <Card id={spec.codpign} className='info-card'>
              <CardContent>
              <IconButton className='card-close' key="close" aria-label="close" size="medium" onClick={deleteSpecieTot}>
                 <CloseIcon className={classes.icon} fontSize="small"/>
               </IconButton>
                <Typography className='titolo-card' color='primary' variant="h6" component="h2"> {spec.genere_specie_sottospecie}</Typography>
                <Typography className={classes.pos} color="textSecondary">Cod-pign: {spec.codpign}</Typography>
                <Typography variant="body2" component="p"><b>N°quadranti:</b> {numeroSegnalazione}</Typography>

                <Typography variant="body2" component="p"><b>Corotipo:</b> {spec.tipocor}</Typography>
                <Typography variant="body2" component="p"><b>Forma biologica:</b> {spec.form_bio}</Typography>


                <List>
                <ExpansionPanel className='sinonimi'>
                 <ExpansionPanelSummary
                   expandIcon={<ExpandMoreIcon />}
                   aria-controls="panel1a-content"
                   id="panel1a-header"
                 >
                <Typography variant="body2" component="p"><b>Sinonimi:</b>  { Array.isArray(spec.sinonimi) ? (<span>{spec.sinonimi.length}</span>):(null)}                </Typography>
                </ExpansionPanelSummary>
                 <ExpansionPanelDetails className={classes.panel}>
                 <div className='sinonimiContainer'>
                 { Array.isArray(spec.sinonimi) ? (spec.sinonimi.map((sin, i)=>
                   <ListItem key={i} className={classes.listItem} dense={true}>
                      <ListItemText className={classes.listaSinonimi}>     {sin.gen_spe_subsp_var}
                      </ListItemText>
                  </ListItem>
                 )):
                 (<ListItem className={classes.listItem} dense={true}><ListItemText className={classes.listaSinonimi}>Non ci sono sinonimi</ListItemText> </ListItem>
                 )}
                   </div>
                 </ExpansionPanelDetails>
               </ExpansionPanel>
                  </List>

              </CardContent>
              </Card>
            )
          })
      );
    }else{
      return(
        <Card className='info-card'>
        <CardContent>
        <IconButton className='card-close' key="close" aria-label="close" size="medium" onClick={deleteSpecieTot}>
           <CloseIcon className={classes.icon} fontSize="small"/>
         </IconButton>
          <Typography className={classes.spec} color='primary'> Informazioni mancanti</Typography>
        </CardContent>
        </Card>
      )
    }


}


const mapDispatchToProps =(dispatch)=>{
  return{
    deleteSpecieTot: (specie)=> dispatch({type:'DELETE_SPECIE_TOT', specie})
  }
}
const mapStateToprops=(state)=>{
  return{
    specie:state.mappa.specie,
    isLoading: state.mappa.loader,
    numeroSegnalazione: state.mappa.numeroSegnalazione
  }
}

export default connect(mapStateToprops, mapDispatchToProps)(InfoSpecie)
