
export const USER_LOADING ='USERL_LOADING';
export const USER_LOADED ='USER_LOADED';
export const AUTH_ERROR ='AUTH_ERROR';
export const LOGIN_SUCCESS ='LOGIN_SUCCESS';
export const LOGIN_FAIL ='LOGIN_FAIL';
export const LOGOUT_SUCCESS ='LOGOUT_SUCCESS';
export const REGISTER_SUCCESS ='REGISTER_SUCCESS';
export const REGISTER_FAIL ='REGISTER_FAIL';

export const MAIL_SUCCESS ='MAIL_SUCCESS';
export const MAIL_FAIL ='MAIL_FAIL';

export const RESET_SUCCESS ='RESET_SUCCESS';
export const RESET_FAIL ='RESET_FAIL';

// check token and load user


export const loadUser =()=>(dispatch, getState) =>{
let auth = getState().auth.isAuthenticated
let tok = getState().auth.token;
let token = JSON.stringify(tok);
let jsonToken = `{"token": ${token}}`
if(tok){
  // dispatch({type: USER_LOADING});
  fetch(`${process.env.REACT_APP_ENDPOINT}/token-refresh/`, {
      method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: jsonToken
      })
      .then(response =>
        response.json().then(json => ({
          status:response.status,
          json
            })
        ))
      .then(
        ({ status, json }) => {
        if( status >= 200 && status < 300) {
            dispatch({
              type: USER_LOADED,
              payload:json
             });
            }
        if (status >= 400 ) {
            dispatch({
                type: AUTH_ERROR,
              })
            }
          }
      )
  .catch(err =>{
    console.log(err);
    // dispatch({
    //   type: AUTH_ERROR,
    // })
  })
}
}


export const login =({username, password})=> dispatch =>{
const body = JSON.stringify({username, password});

  fetch(`${process.env.REACT_APP_ENDPOINT}/token-auth/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body
      })
      .then(response =>
        response.json().then(json => ({
          status:response.status,
          json
            })
        ))
      .then(
        ({ status, json }) => {
        if( status >= 200 && status < 300) {
            dispatch({
              type: LOGIN_SUCCESS,
              payload: json
             });
            }
        if (status >= 400 ) {
            dispatch({
                type: LOGIN_FAIL,
                payload:json.non_field_errors[0]
              })
            }
          }
      )
  .catch(err =>{
    dispatch({
      type: LOGIN_FAIL,
      payload:'errore durante la registrazione'
    })
  })
}




export const register = ({first_name, last_name, email, password})=> dispatch =>{

var body = JSON.stringify({first_name, last_name, email, password});
fetch(`${process.env.REACT_APP_ENDPOINT}/core/users/`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body
  })
    .then(response =>
        response.json().then(json => ({
          status:response.status,
          json
            })
        ))
      .then(
        ({ status, json }) => {
        if( status >= 200 && status < 300) {
            dispatch({
              type: REGISTER_SUCCESS,
              payload: json
             });
            }
        if (status >= 400 ) {
          console.log(json);
            dispatch({
                type: REGISTER_FAIL,
                payload:json.email[0]
              })
            }
          }
      )
    .catch(err =>{
    dispatch({
      type: REGISTER_FAIL,
      payload:'errore di registrazione'
    })
    })
}





// send mail
export const sendMail = (values)=> dispatch =>{

var body = JSON.stringify(values);

fetch(`${process.env.REACT_APP_ENDPOINT}/core/password/reset/`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body
  })
    .then(response =>
        response.json().then(json => ({
          status:response.status,
          json
            })
        ))
      .then(
        ({ status, json }) => {
        if( status >= 200 && status < 300) {
            dispatch({
              type: MAIL_SUCCESS,
              payload: json
             });
            }
        if (status >= 400 ) {
            dispatch({
                type: MAIL_FAIL,
                payload:json.email[0]
              })
            }
          }
      )
    .catch(err =>{
    dispatch({
      type: MAIL_FAIL,
      payload:'invio non riuscito'
    })
    })
}




// resetPsw
export const resetPsw = (data)=> (dispatch, getState) =>{

let body = JSON.stringify(data);

fetch(`${process.env.REACT_APP_ENDPOINT}/core/password/reset/confirm/`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  body
  })
    .then(response =>
        response.json().then(json => ({
          status:response.status,
          json
            })
        ))
      .then(
        ({ status, json }) => {
        if( status >= 200 && status < 300) {
            dispatch({
              type: RESET_SUCCESS,
              payload: json
             });
            }
        if (status >= 400 ) {
            dispatch({
                type: RESET_FAIL,
                payload:json.email[0]
              })
            }
          }
      )
    .catch(err =>{
    dispatch({
      type: RESET_FAIL,
      payload:'invio non riuscito'
    })
    })
}



// logout user
export const logout =()=>(dispatch, getState) =>{
    dispatch({
      type: LOGOUT_SUCCESS,
    });
}


// setup config with token

export const tokenConfig = getState =>{
  const token = getState().auth.token;
  // headers
const config ={
  headers:{
    'Content-Type': 'application/json'
  }
}
  // if token add to headers config
  if(token){
    config.headers['Authorization'] =`Token ${token}`;
  }
  return config
}
