import React  from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Img from '../outdoor.png'

import {connect} from 'react-redux'
import { resetPsw } from '../components/store/actions/authAction'


import { useFormik } from 'formik';
import * as Yup from 'yup';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="http://www.floralpinabergamasca.net/">
        FAB
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  img:{
    position:'absolute',
    width:'100%',
    height:'100vh',
    opacity:.5,
    top:0,
    left:0,
    zIndex:-1,
  },
  bkg:{
    backgroundColor:'#719993',
    position:'absolute',
    zIndex: -2,
    width:'100%',
    height:'100vh',
    top:0,
    left:0,
  },
  link:{
    marginTop: 15
  }
}));


function RestorePsw({resetPsw, resetSuccess, resetFail}) {
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      password:'',
      passwordConfirm:''
    },
    validationSchema: Yup.object({
        password: Yup.string()
          .required('inserire la password')
          .min(8, 'password troppo breve - deve essere almeno 8 caratteri')
          .matches(/[a-zA-Z]/, 'La password deve contere solo lettere latine'),
        passwordConfirm: Yup.string()
          .oneOf([Yup.ref('password'), null], "Le password non coincidono")
          .required('confermare la password')
      }),
    onSubmit: values => {

      let url = window.location.href;
      let trim = url.split('/')

      let data = {
        "uid": trim[4],
        "token":trim[5],
        "new_password1": values.password,
        "new_password2": values.passwordConfirm,
      }
      resetPsw(data);
    },
  });



if(resetSuccess){
  return (
    <Container className='containerCard' component="main" maxWidth="xs">
    <img src={Img} className={classes.img} alt=' '/>
    <div className={classes.bkg}></div>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography className={classes.link} component="h1" variant="h5">
          {resetSuccess}
        </Typography>
        <Typography className={classes.link} component="h1" variant="h5">
          Vai alla pagina di login per accedere
        </Typography>
        <Link href="/Signin" color="primary">
          Login
        </Link>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}else{
  return (
    <Container className='containerCard' component="main" maxWidth="xs">
    <img src={Img} className={classes.img} alt=' '/>
    <div className={classes.bkg}></div>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Imposta nuova password
        </Typography>
        <form onSubmit={formik.handleSubmit} className={classes.form} noValidate>

          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Nuova Password"
            type="password"
            id="password"
            autoComplete="password"
            onChange={formik.handleChange}
            value={formik.values.password}
          />

          {formik.touched.password && formik.errors.password ? (
    <div>{formik.errors.password}</div>
  ) : null}


      <TextField
        variant="outlined"
        required
        fullWidth
        name="passwordConfirm"
        label="Conferma password"
        type="password"
        id="passwordConfirm"
        autoComplete="current-password"
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values.passwordConfirm}
      />
      {formik.touched.passwordConfirm && formik.errors.passwordConfirm ? (
    <div>{formik.errors.passwordConfirm}</div>
    ) : null}

    { resetFail ? (  <Typography component="h1">
        {resetFail}
      </Typography>): null
    }

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Invia
          </Button>

        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}

}


const mapStateToProps = state =>({
resetSuccess: state.auth.resetSuccess,
resetFail: state.auth.resetSuccess

});

export default connect(mapStateToProps,{resetPsw})(RestorePsw);
